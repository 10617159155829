import React, { useState } from 'react'
import { CpCard, CpButton, CpIcon } from 'canopy-styleguide!sofe'
import { useCss, k, t, a } from 'kremling'
import { track } from '../../common/analytics.helper'
// Import styles along with kremling due to a kremling bug with @keyframes
import styles from './banner-notification.styles.css'

const parentObjectIcons = {
  email: 'communication-envelope-small',
  task: 'checkmark-circle-open-small',
  file: 'file'
}

const bannerTypeIcons = {
  urgent: 'urgent-filled-large',
  reminder: 'misc-bell',
  comment: 'communication-chat-bubbles'
}

export default function BannerNotification(props) {
  const {
    notification,
    show,
    expanding,
    rightOffset,
    topOffset,
    onClose,
    onAfterClose,
    onMouseOver,
  } = props

  const {
    banner_type,
    banner_body,
    clients,
    PARENT_OBJECT_TYPE,
    PARENT_OBJECT_SUBJECT,
    title,
    URL,
  } = notification

  const scope = useCss(css)
  const urgent = banner_type === 'urgent'

  return (
    <a
      {...scope}
      onClick={() => {
        track('notifications_comments.banner-clicked', { redirect_to_message: true })
        onClose()
      }}
      onMouseOver={onMouseOver}
      href={URL}
      className="banner-notification-link"
    >
      <CpCard
        onAnimationEnd={() => {
          if (!show) {
            onAfterClose?.()
          }
        }}
        className={a("banner-notification").t(styles['banner-notification--animate-in'], styles['banner-notification--animate-out'], show).m('banner-notification--expanding', expanding)}
        style={{
          right: rightOffset,
          top: topOffset
        }}
        level={3}
      >
        <div className="cp-flex">
          <CpIcon className="type-icon" name={bannerTypeIcons[banner_type]} fill={urgent ? "var(--cp-color-notification-badge-bg)" : "var(--cp-color-app-icon)"} />
          <div className="main-content cp-ml-8 cp-mt-4">
            <div className="title cp-ellipsis">{title}</div>
            <div className={t("message--urgent message", "message cp-ellipsis", urgent)} dangerouslySetInnerHTML={{ __html: banner_body }} />
            {!urgent &&
              <div className="context-grid">
                <div className="context-container">
                  <CpIcon fill="var(--cp-color-app-icon)" className="context-icon" name="person-small" />
                  <div className="client-name-container">
                    <div className="context-text cp-ellipsis">{clients?.length > 0 ? clients[0].name : 'No client'}</div>
                    {clients?.length > 1 &&
                      <div className="client-count">, +{clients?.length-1}</div>
                    }
                  </div>
                </div>
                <div className="context-container">
                  <CpIcon fill="var(--cp-color-app-icon)" className="context-icon" name={parentObjectIcons[PARENT_OBJECT_TYPE]} />
                  <div className="context-text cp-ellipsis">{PARENT_OBJECT_SUBJECT}</div>
                </div>
              </div>
            }
          </div>
        </div>
        <CpButton
          aria-label="Close notification"
          icon="close-small"
          className="close-button"
          onClick={(e) => {
            e.preventDefault()
            track('notifications_comments.banner-clicked', { banner_dismissal_active: true })
            onClose()
          }}
        />
      </CpCard>
    </a>
  )
}

const css = k`
  .banner-notification--expanding {
    box-shadow: var(--cp-box-shadow-l3) !important;
  }

  .banner-notification {
    width: 34rem;
    height: 10rem;
    padding: 1.6rem;
    position: absolute;
    transition: all 0.2s ease;
    box-shadow: 0rem 0.4rem 0.9rem 0rem rgb(0 0 0 / 20%);
  }

  .banner-notification-link {
    color: inherit;
    text-decoration: inherit;
  }

  .title {
    font-weight: 600;
  }

  .message {
    font-size: 1.2rem;
    margin-top: 0.4rem;
    max-height: 1.8rem;
  }

  .message > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
  }

  .message--urgent {
    height: 4rem;
    overflow: hidden;
  }

  .main-content {
    width: 100%;
    min-width: 0;
  }

  .type-icon {
    flex-shrink: 0;
  }

  .context-grid {
    display: grid;
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
    grid-template-rows: 1fr;
    gap: 0rem 0rem;
    grid-template-areas: ". .";
    justify-content: center;
  }

  .context-container {
    display: flex;
    margin-top: 0.4rem;
    align-items: center;
  }

  .context-icon {
    flex-shrink: 0;
  }

  .context-text {
    font-size: 1.2rem;
  }

  .client-count {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .client-name-container {
    overflow: auto;
    display: flex;
    margin-right: 0.4rem;
  }

  .close-button {
    position: absolute;
    right: 0;
    top: 0;
  }
`
