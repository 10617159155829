import React, { useEffect, useState, useRef } from 'react'
import { v4 as uuid } from 'uuid';
import { useCss, k, a } from 'kremling'
import { throttle } from 'lodash'
import { catchSyncStacktrace } from 'auto-trace'
import { useWithUserAndTenant } from 'cp-client-auth!sofe'
import { onPusher } from 'fetcher!sofe';
import { CpButton, usePreviousState } from 'canopy-styleguide!sofe'

import BannerNotification from './banner-notification.component'
import { getBannerNotification } from './banner-notifications-controller.resource'

const notificationHeight = 100
const notificationPadding = 8
const notificationTimeout = 10000
const maxNotifications = 5

export default function BannerNotificationsController(props) {
  const scope = useCss(css)
  const [user, tenant] = useWithUserAndTenant()

  const [notifications, setNotifications] = useState([])
  const [expanding, setExpanding] = useState(false)

  const visibleNotifications = Math.min(notifications.length, maxNotifications)
  // Using this to keep the height of the mouseLeave div the same, so that collapsing works better
  const prevVisibleNotifications = usePreviousState(visibleNotifications)
  const mouseLeaveDetectorRef = useRef()

  useEffect(() => {
    if (user) {
      const sub = onPusher('banner-notification').subscribe(result => {
        getBannerNotification(result.banner_id).subscribe(notification => {
          addNotification(notification)
        }, (err) => {
          // There is a possibility of the notification no longer existing, in that case we ignore
          if (err.status === 404) return;
          catchSyncStacktrace(err)
        })
      }, catchSyncStacktrace)
      return () => sub.unsubscribe()
    }
  }, [user?.id])

  useEffect(() => {
    if (expanding) {
      // Using an actual mouseLeave event causes inconsistent collapsing behavior
      // i.e. it doesn't fire all the time, like when moving quickly past the element
      const collapseIfNotHovering = throttle((e) => {
        const rect = mouseLeaveDetectorRef.current.getBoundingClientRect()
        if (e.clientX < rect.left || e.clientX > rect.right &&
            e.clientY < rect.top  || e.clientY > rect.bottom) {
          collapse()
        }
      }, 100)
      const listener = (e) => collapseIfNotHovering(e)
      window.addEventListener('mousemove', listener)
      return () => window.removeEventListener('mousemove', listener)
    }
  }, [expanding])

  function addNotification(notification) {
    const id = uuid()
    setNotifications(notifications => [...notifications, {
      ...notification,
      uuid: id,
      show: true,
      dismissTimeout: setTimeout(() => dismissNotification(id), notificationTimeout)
    }])
  }

  function dismissNotification(notificationId) {
    setNotifications(notifications => notifications.map(notification => {
      if (notification.uuid === notificationId) {
        return {
          ...notification,
          show: false
        }
      } else {
        return notification
      }
    }))
  }

  function expand() {
    setExpanding(true)
    notifications.forEach((notification) => clearTimeout(notification.dismissTimeout))
  }

  function collapse() {
    setExpanding(false)
    setNotifications(notifications => notifications.map(notification => ({
      ...notification,
      dismissTimeout: setTimeout(() => dismissNotification(notification.uuid), notificationTimeout)
    })))
  }

  function calcTopOffset(index) {
    const position = visibleNotifications - (index + 1)
    if (expanding) {
      return position * (notificationHeight + notificationPadding)
    } else {
      return position * notificationPadding
    }
  }

  if (!user) return null
  return (
    <div {...scope} >
      <div
        className="mouse-leave-detector"
        style={{
          height: (((notificationHeight + notificationPadding) * prevVisibleNotifications) + 24) + 'px'
        }}
        hidden={!expanding}
        ref={mouseLeaveDetectorRef}
      >
      </div>
      <div className="banner-notifications">
        {notifications.slice(-maxNotifications).map((notification, i) => (
          <BannerNotification
            key={notification.uuid}
            notification={notification}
            show={notification.show}
            expanding={expanding}
            rightOffset={expanding ? 0 : i * notificationPadding}
            topOffset={calcTopOffset(i)}
            onMouseOver={expand}
            onClose={() => dismissNotification(notification.uuid)}
            onAfterClose={() => setNotifications(notifications.filter(n => n.uuid !== notification.uuid))}
          />
        ))}
      </div>
    </div>
  )
}

const css = k`
  .banner-notifications {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 150000;
    margin-right: 2.4rem;
    margin-top: 2.4rem;
  }

  .mouse-leave-detector {
    position: fixed;
    top: 0;
    right: 0;
    width: 40rem;
  }
`
