exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-exports-banner-notifications-banner-notification-styles__banner-notification--animate-in {\n  animation: src-exports-banner-notifications-banner-notification-styles__bannerNotificationSlideIn 400ms ease;\n}\n\n.src-exports-banner-notifications-banner-notification-styles__banner-notification--animate-out {\n  animation: src-exports-banner-notifications-banner-notification-styles__bannerNotificationSlideOut 400ms ease;\n}\n\n@keyframes src-exports-banner-notifications-banner-notification-styles__bannerNotificationSlideIn {\n  0% {\n    opacity: 0;\n    transform: translateX(100%) scale(0.92);\n  }\n\n  100% {\n    opacity: 1;\n    transform: translateX(0%) scale(1);\n  }\n}\n\n@keyframes src-exports-banner-notifications-banner-notification-styles__bannerNotificationSlideOut {\n  0% {\n    opacity: 1;\n    transform: translateX(0%) scale(1);\n  }\n\n  99% {\n    opacity: 0;\n  }\n\n  100% {\n    display: none;\n    opacity: 0;\n    transform: translateX(100%) scale(0.92);\n  }\n}\n", ""]);

// exports
exports.locals = {
	"banner-notification--animate-in": "src-exports-banner-notifications-banner-notification-styles__banner-notification--animate-in",
	"bannerNotificationSlideIn": "src-exports-banner-notifications-banner-notification-styles__bannerNotificationSlideIn",
	"banner-notification--animate-out": "src-exports-banner-notifications-banner-notification-styles__banner-notification--animate-out",
	"bannerNotificationSlideOut": "src-exports-banner-notifications-banner-notification-styles__bannerNotificationSlideOut"
};